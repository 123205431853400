import { useMemo } from 'react'

import type { VariantsInput } from './styles'
import {
  ContentWrapper,
  HeaderWrapper,
  TranscriptContainer,
  TranscriptTextWrapper,
  Wrapper,
} from './styles'

import { Background } from 'components/Background'
import { IconButton } from 'components/Button'
import { ContentLayout, getContentLayout } from 'components/ContentLayout'
import { HTML_CONTENT_FORMATTING } from 'components/HtmlContent'
import { Modal } from 'components/Modal'
import { IconMaximizeOutline, IconMinimizeOutline } from 'components/icons'
import { Text } from 'components/typography'
import { useAppContext } from 'context/AppContext'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { useModalState } from 'hooks/useModalState'
import type { EsConfig } from 'schemas/eventsync-config'
import { PageEventType, usePageEventTracking } from 'utils/rudderstack'
import { useSettings } from 'utils/settings'

type Props = {
  content: EsConfig | null
  /**
   * Will prevent the transcript from expanding
   * beyond the safe zone
   */
  safeZonePx?: number
  /**
   * The id of the player container
   * to prevent scrolling when this is open
   */
  playerContainerId?: string
}
const TRANSCRIPT_ID = 'transcript-modal'

export function Transcript({
  content,
  safeZonePx = 0,
}: Props): JSX.Element | null {
  const modalProps = useModalState(TRANSCRIPT_ID)
  const { contentBundle } = useAppContext()
  const { settings } = useSettings()

  const { _ } = useLocaleTranslation('Transcript')

  const variantInput: VariantsInput = useMemo(() => {
    // Viewport Height
    const viewportHeight = window.innerHeight

    return {
      isOpen: modalProps.show,
      openHeight: viewportHeight - safeZonePx,
    }
  }, [modalProps.show, safeZonePx])

  usePageEventTracking({
    pageName: PageEventType.TRANSCRIPT,
    isShowing: modalProps.show,
  })

  if (!content) {
    return null
  }

  /**
   * Get the content layout for the transcript slot so we can check
   * it before we render
   */
  const transcriptContent = getContentLayout({
    contentBundle,
    entity: content,
    slotName: 'transcript',
    settings,
  })
  if (!transcriptContent) {
    return null
  }

  return (
    <Wrapper>
      <TranscriptContainer
        custom={variantInput}
        // Allows expanding by clicking anywhere in the container
        // but not collapsing so the user can scroll the transcript
        onClick={modalProps.onClick}
      >
        {/* External transcript bar */}
        <HeaderWrapper>
          <Text $weight="bold" $size="large">
            {_('title')}
          </Text>
          <IconButton
            Icon={modalProps.show ? IconMinimizeOutline : IconMaximizeOutline}
            size="medium"
            variant="inversed"
            onClick={modalProps.toggleShowing}
          />
        </HeaderWrapper>
      </TranscriptContainer>

      {/* Internal Modal that shows */}
      <Modal
        id={TRANSCRIPT_ID}
        isShowing={modalProps.show}
        onClose={modalProps.onClose}
        aria-label={_('title')}
      >
        <HeaderWrapper>
          <Text $weight="bold" $size="large">
            {_('title')}
          </Text>
          <IconButton
            Icon={modalProps.show ? IconMinimizeOutline : IconMaximizeOutline}
            size="medium"
            variant="inversed"
            onClick={modalProps.toggleShowing}
          />
        </HeaderWrapper>
        <ContentWrapper>
          <Background blurLevel={3} />

          <TranscriptTextWrapper>
            <ContentLayout
              pwaObject={content}
              slotName="transcript"
              allowedHtmlTags={HTML_CONTENT_FORMATTING}
            />
          </TranscriptTextWrapper>
        </ContentWrapper>
      </Modal>
    </Wrapper>
  )
}
