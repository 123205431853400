import { ErrorSection, ErrorContent, ErrorFooter } from './styles'

import { Button } from 'components/Button'
import { Screen } from 'components/Screen'
import { Headline, Text } from 'components/typography'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { AppContainer, AppFrame, AppViewport } from 'layouts/styles'
import { PageEventType, usePageEventTracking } from 'utils/rudderstack'

/**
 * A catch-all error view for unhandled errors.
 */
export const CatchAll = (): JSX.Element => {
  const { _, t } = useLocaleTranslation('Error.generic')
  usePageEventTracking({
    pageName: PageEventType.ERROR,
  })
  return (
    <Screen>
      <ErrorSection>
        <ErrorContent>
          <Headline>{_('title')}</Headline>
          <Text>{_('description')}</Text>
        </ErrorContent>

        <ErrorFooter>
          {/* Reload Event Sync Application  */}
          <Button onClick={() => window.location.reload()}>
            {t('Error.retry-button')}
          </Button>
        </ErrorFooter>
      </ErrorSection>
    </Screen>
  )
}

/**
 * A catch-all error view for use with ErrorBoundary.
 */
export const CatchAllFullpage = (): JSX.Element => (
  <AppFrame>
    <AppViewport>
      <AppContainer>
        <CatchAll />
      </AppContainer>
    </AppViewport>
  </AppFrame>
)
