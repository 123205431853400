import type { Typename } from '@apsys/gazelle'

import { getEsConfig } from './bundle'

import type {
  Loader as ContentLoader,
  ManualIntegration,
  TimecodeIntegration,
  UdpIntegration,
} from 'schemas/eventsync-config'

/**
 * Connection type labels, used for analytics to send in the meta, as we
 * use TIMECODE as a connection type, but we want to display it as SMPTE LTC.
 */
export enum INTEGRATION_LABELS {
  UDP = 'UDP',
  MANUAL = 'MANUAL',
  TIMECODE = 'TIMECODE',
}

/**
 * Get the playlists from the content bundle
 *
 * @param contentBundle
 */
export const getPlaylists = (
  contentBundle: ContentLoader | null,
): Array<ManualIntegration | UdpIntegration | TimecodeIntegration> => {
  if (!contentBundle) return []

  // Get the integration reference from the es-config
  const esConfig = getEsConfig(contentBundle)

  const integrationPlaylists = esConfig?.playlists
  if (!integrationPlaylists) {
    console.error('No integration playlists found')
    return []
  }

  const resolvedPlaylists = integrationPlaylists.flatMap(playlist =>
    contentBundle.getAll(
      playlist.typename as Typename<
        ManualIntegration | UdpIntegration | TimecodeIntegration
      >,
    ),
  )

  if (resolvedPlaylists.length === 0) {
    console.error('No integration playlists data found')
    return []
  }

  return resolvedPlaylists
}
