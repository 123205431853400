import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

import { indexOf } from 'lodash'
import noop from 'lodash/noop'

import type { GazelleRef } from '@apsys/gazelle'

import { BLANK_TRACK } from '../constants'

import { useLocalStorageState } from 'hooks/useLocalStorageState'
import type { Loader as ContentLoader } from 'schemas/eileen-service'
import type { Loader as ManifestLoader } from 'schemas/eileen-service-manifest'
import type { Loader as StylesheetLoader } from 'schemas/eileen-service-stylesheet'
import { EileenColorScheme } from 'schemas/eileen-service-stylesheet'
import { Audio } from 'schemas/vixen-assets'
import { getThemeVariant, setThemeVariant } from 'storage/local-storage'
import type { EventTrack } from 'types/player'
import { retrieveAudioAsset } from 'utils/assets'
import { loadAllBundles } from 'utils/bundle'
import { debugLog } from 'utils/debug'
import { getPlaylists } from 'utils/getIntegration'
import { getTimelineEntryByAudio } from 'utils/getTimelineEntryByAudio'
import { useSettings } from 'utils/settings'
/**
 * For now, we're storing the key details for the app in context and localstorage.
 *
 * If this grows, please break it into smaller contexts so we don't end up in
 * render hell.
 */
type AppContextProps = {
  /** The pre-parsed content bundle. */
  contentBundle: ContentLoader | null
  /** The pre-parsed dynamic stylesheet. */
  stylesheetBundle: StylesheetLoader | null
  /** The pre-parsed manifest */
  manifestBundle: ManifestLoader | null

  /** If the content has loaded from the bundle, set this to true. */
  contentLoaded: boolean
  /** If the content load fails, we want to show a whole-view loading failure. */
  loadFailure: boolean
  /**
   * The current (preferred) theme variant.
   *
   * In most cases, you're looking for the currently _rendering_ theme variant,
   * which is `const { mode } = useTheme()`.
   */
  themeVariant: EileenColorScheme.Mode
  /** Update the theme variant (in localstorage also) */
  setThemeVariant: (value: EileenColorScheme.Mode | null) => void
  tracks: EventTrack[]
  setTracks: (tracks: EventTrack[]) => void
}

export const AppContext = createContext<AppContextProps>({
  contentBundle: null,
  stylesheetBundle: null,
  manifestBundle: null,

  contentLoaded: false,
  loadFailure: false,

  themeVariant: EileenColorScheme.Mode.DARK,
  setThemeVariant: noop,

  tracks: [],
  setTracks: noop,
})

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }
  return context
}

/** The bundle context props */
type BundleProps = Pick<
  React.ContextType<typeof AppContext>,
  'contentBundle' | 'stylesheetBundle' | 'manifestBundle'
>

export const AppContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [bundles, setBundles] = useState<BundleProps>({
    contentBundle: null,
    stylesheetBundle: null,
    manifestBundle: null,
  })
  const { settings } = useSettings()

  const [tracks, setTracks] = useState<EventTrack[]>([])

  const [loadingState, setLoadingState] = useState<
    'loading' | 'ready' | 'failed'
  >('loading')

  const [themeVariant, setCtxThemeVariant] = useLocalStorageState(
    getThemeVariant,
    setThemeVariant,
  )

  const getAudioAsset = (
    contentBundle: ContentLoader,
    audioRef: GazelleRef<Audio>,
    language: string,
  ) => {
    const audio = contentBundle?.get(audioRef)

    if (!audio) {
      console.warn('Unknown `audio`:', audioRef)
      return null
    }

    return retrieveAudioAsset({
      contentBundle,
      ref: audio.ref,
      settings,
      language,
    })
  }

  // const systemThemeVariant = useSystemThemeVariant();

  useEffect(() => {
    async function setup(): Promise<void> {
      try {
        const value = await loadAllBundles()
        setBundles(value)

        debugLog('Stylesheet in bundle?', !!value.stylesheetBundle)

        setLoadingState('ready')
      } catch (exc) {
        setLoadingState('failed')

        console.error('Bundle setup failed', exc)
      }
    }
    const searchParams = location.search
    window.history.replaceState({}, '', `/${searchParams}`)
    setup()
  }, [])

  useEffect(() => {
    if (!bundles.contentBundle) return

    const audioData = bundles.contentBundle.getAll(Audio)
    const playlists = getPlaylists(bundles.contentBundle)

    const resolvedAudioTracks = audioData
      ?.map((track): EventTrack | null => {
        if (!bundles.contentBundle || !playlists) return null

        const asset = getAudioAsset(bundles.contentBundle, track.ref, 'en')
        const resolvedAudio = getTimelineEntryByAudio(playlists, track.ref)
        const url = asset?.url

        if (!url || !asset || !resolvedAudio) {
          console.warn(
            `Warning, unable to find data.  url: ${url} asset: ${asset} resolvedAudio: ${JSON.stringify(resolvedAudio)}`,
          )
          return null
        }

        return {
          title: track.name && track.name[settings.defaultLanguage],
          url,
          sequence: indexOf(audioData, track) || 1,
          asset,
          audio: track,
          integrationData: resolvedAudio,

          // PES-200
          // TODO Might be removing long audio in future 👀
          longAudio: track.assets && track.assets.length > 1,
        }
      })
      .filter((t): t is NonNullable<typeof t> => t !== null)

    console.info('Resolved Audio Tracks', resolvedAudioTracks)

    // Add Blank Track to preloaded tracks
    // @ts-ignore We don't care that this data is not a full EventTrack
    resolvedAudioTracks?.push(BLANK_TRACK)

    setTracks(resolvedAudioTracks || [])
  }, [bundles])

  return (
    <AppContext.Provider
      value={{
        ...bundles,

        loadFailure: loadingState === 'failed',
        contentLoaded: loadingState === 'ready',

        // If the user hasn't selected a theme, we determine
        // the variant based on the system/browser settings.
        themeVariant: themeVariant ?? EileenColorScheme.Mode.DARK,
        setThemeVariant: setCtxThemeVariant,

        tracks,
        setTracks,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
