import memoize from 'lodash/memoize'
import { isEmpty } from 'radash'

import type { GazelleRef } from '@apsys/gazelle'

import type { ConfigOptions } from './settings'

import type { Loader as ContentLoader } from 'schemas/eileen-service'
import type { Audio, AudioAsset, Picture } from 'schemas/vixen-assets'
import { RasterImageAsset } from 'schemas/vixen-assets'

/**
 * A memoized function to get a media element
 * we don't need to recreate this multiple times
 */
const getMediaElement = memoize(() => document.createElement('audio'))

type RetrieveAssetFunctionParams<TEntity> = {
  contentBundle: ContentLoader
  ref: GazelleRef<TEntity> | undefined
  settings: ConfigOptions
  language?: string | null
}

type RetrieveAssetFunction<TEntity, TAsset> = (
  params: RetrieveAssetFunctionParams<TEntity>,
) => TAsset | null

/**
 * Retrieve the picture assets for a given picture entity.
 *
 * This will only return assets which are `unrestricted`.
 */
export const retrievePictureAssets: RetrieveAssetFunction<
  Picture,
  RasterImageAsset[]
> = ({ contentBundle, ref, settings, language }) => {
  const picture = ref && contentBundle.get(ref)
  if (!picture) return []

  const retrieve = (lang?: string | null): RasterImageAsset[] | null => {
    const assets = picture.assets.flatMap((assetRef: GazelleRef) => {
      const asset = contentBundle.get(assetRef)

      if (!asset || !(asset instanceof RasterImageAsset)) {
        return []
      }

      // Asset must be allowed in all situations
      if (asset?.distributionControl !== 'unrestricted') return []

      // Asset must be in the correct language
      // `undefined` from Gazelle means `null`
      if ((asset?.language ?? null) !== lang) return []

      return [asset]
    })

    if (!assets.length) {
      return null
    }

    return assets
  }

  // Follows the RFC0110 rules for asset selection based on language
  // using what's available as not all language selection options
  // described in the RFC are available in the PWA.
  return (
    // Tries to get the asset in the selected UI language (a)
    retrieve(language) ??
    // Tries to get the asset based on the default language in settings (b)
    retrieve(settings.defaultLanguage) ??
    // Tries to get the first asset which the language is not set (f)
    retrieve(null)
  )
}

/**
 * Retrieve the audio asset for a given audio entity.
 *
 * Follows the RFC0110 rules for asset selection.
 */
export const retrieveAudioAsset: RetrieveAssetFunction<Audio, AudioAsset> = ({
  contentBundle,
  ref,
  settings,
  language,
}) => {
  const audio = ref && contentBundle.get(ref)
  if (!audio) return null

  const retrieve = (lang?: string | null): AudioAsset | null => {
    /** Resolved audio-assets */
    const assets = audio.assets.flatMap((ref: GazelleRef) => {
      const asset = contentBundle.get(ref)

      if (!asset) {
        console.warn('Unknown `audio-asset`:', ref)
        return []
      }
      return [asset]
    })

    const mediaElement = getMediaElement()
    return (
      assets.find((asset: AudioAsset) => {
        // Asset must be allowed in all situations
        if (asset.distributionControl !== 'unrestricted') return false

        // Asset must be in the correct language
        // `undefined` from Gazelle means `null`
        if ((asset.language ?? null) !== lang) return false

        // PES-200
        // FIXME Return true if this data is not here anyway,
        // but this needs to be removed once the below ticket is finished
        // and the correct data is there
        // https://artprocessors.atlassian.net/browse/PES-265
        if (isEmpty(asset.contentType)) return true
        if (!mediaElement.canPlayType(asset.contentType)) return false

        return true
      }) ?? null
    )
  }

  // Follows the RFC0110 rules for asset selection based on language
  // using what's available as not all language selection options
  // described in the RFC are available in the PWA.
  return (
    // Tries to get the asset in the selected UI language (a)
    retrieve(language) ??
    // Tries to get the asset based on the default language in settings (b)
    retrieve(settings.defaultLanguage) ??
    // Tries to get the first asset which the language is not set (f)
    retrieve(null)
  )
}
