import { useEffect } from 'react'

import { styled } from 'styled-components'

import { Background } from 'components/Background'
import { IconButton } from 'components/Button'
import { ContentLayout, getContentLayout } from 'components/ContentLayout'
import { HTML_CONTENT_FORMATTING } from 'components/HtmlContent'
import { LayoutNav } from 'components/LayoutNav'
import { Modal, ModalContent } from 'components/Modal'
import { IconXCloseOutline, IconInfoOutline } from 'components/icons'
import { useAppContext } from 'context/AppContext'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { useModalState } from 'hooks/useModalState'
import type { EsConfig } from 'schemas/eventsync-config'
import { space } from 'theme/utils'
import { PageEventType, usePageEventTracking } from 'utils/rudderstack'
import { useSettings } from 'utils/settings'

const INFORMATION_CONTAINER_ID = 'information-modal'

type Props = {
  /**
   * The PWA object to display metadata for
   */
  content: EsConfig | null
}

const ModalButton = styled(IconButton)`
  margin-bottom: ${space(10)};
  align-self: center;
`

export const Information = ({ content }: Props): JSX.Element | null => {
  const { _ } = useLocaleTranslation('Information')
  const { contentBundle } = useAppContext()
  const { settings } = useSettings()

  const modalProps = useModalState(INFORMATION_CONTAINER_ID)
  useEffect(() => {
    modalProps.onClose?.()
    // We only want to react to the location changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  usePageEventTracking({
    pageName: PageEventType.INFO,
    isShowing: modalProps.show,
  })

  if (!content) {
    return null
  }

  /**
   * Get the content layout for the info slot so we can check
   * it before we render
   */
  const infoContent = getContentLayout({
    contentBundle,
    entity: content,
    slotName: 'information',
    settings,
  })
  if (!infoContent) {
    return null
  }

  return (
    <>
      <ModalButton
        Icon={IconInfoOutline}
        onClick={modalProps.onClick}
        size="medium"
        appearance="overlay"
        aria-label={_('open-button')}
        aria-controls={modalProps.name}
        aria-expanded={modalProps.ariaExpanded}
      />
      <Modal
        // the id of the element and for the animation
        id={INFORMATION_CONTAINER_ID}
        isShowing={modalProps.show}
        onClose={modalProps.onClose}
        aria-label={_('title')}
      >
        <Background blurLevel={2} />
        <LayoutNav variant="ghost">
          <IconButton
            Icon={IconXCloseOutline}
            onClick={modalProps.onClose}
            size="medium"
            variant="inversed"
            aria-label={_('close-button')}
          />
        </LayoutNav>
        <ModalContent>
          {content && (
            <ContentLayout
              pwaObject={content}
              slotName="information"
              allowedHtmlTags={HTML_CONTENT_FORMATTING}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
