import { useMemo } from 'react'

import type { RudderAnalytics } from '@rudderstack/analytics-js'
import type { DeepOmit } from 'deep-utility-types'

import { getManifestRef } from '../bundle'

import { getRudderstackInstance } from './getRudderstackInstance'
import type { PageEvent } from './pageSchema'
import { ZPageEvent } from './pageSchema'
import type { TrackEvent } from './trackSchema'
import { ZTrackEvent } from './trackSchema'
import { useCommonRudderstackData } from './useRudderstackCommonData'

import { useAppContext } from 'context/AppContext'
import { useConfigProperties } from 'hooks/useEsConfig'
import { getDeviceID } from 'storage/local-storage'
import { debugAnalytics, debugError } from 'utils/debug'

/**
 * The options for the page event, with the options that we set as
 * defaults in the method not passed in.
 */
export type PageOptions = DeepOmit<
  PageEvent,
  'properties.bundle' | 'apiOptions'
>

/**
 * The options for the track event, with the options that we set as
 * defaults in the method not passed in.
 */
export type TrackOptions = DeepOmit<
  TrackEvent,
  'properties.bundle' | 'apiOptions'
>

type RType = {
  rudderstack: RudderAnalytics
  page: (options: PageOptions) => void
  track: (options: TrackOptions) => void
}

/**
 * A hook to get the Rudderstack instance.
 *
 * This will return the Rudderstack instance, and wrapped events that we expect to use.
 *
 * @category Rudderstack
 */
export const useRudderstack = (): RType => {
  // Ensure initialization
  const instance = getRudderstackInstance()
  const commonData = useCommonRudderstackData()
  const { manifestBundle } = useAppContext()
  const { integrationType } = useConfigProperties()

  const connection_type = integrationType
    ? integrationType.toLowerCase()
    : undefined

  return useMemo(() => {
    const deviceId = getDeviceID()

    const bundle = {
      id: getManifestRef(manifestBundle)?.id || '',
      date: manifestBundle?.publishedAt?.toISOString() || '',
    }

    /**
     * Wrapper around page event to include common data. Don't include api options
     * as this is handled by the common data.
     */
    const page = (options: PageOptions): void => {
      debugAnalytics(options)

      const result = ZPageEvent.safeParse({
        ...options,
        properties: {
          ...options.properties,
          bundle,
          custom: {
            ...options.properties.custom,
            connection_type,
          },
        },
        apiOptions: {
          ...commonData,
          anonymousId: deviceId,
        },
      })

      if (result.success) {
        const { category, name, properties, apiOptions } = result.data

        instance.page(category, name, properties, apiOptions)
      } else {
        const errors = result.error.errors
          .map(elem => `${elem.path}: ${elem.message}`)
          .join('\n')

        debugError('Sending Page Event', errors)
      }
    }

    /**
     * Wrapper around the Rudderstack `track` event to include common data.
     *
     * - Add on bundle info
     * - Add on custom object
     *
     * Don't include api options as this is handled by the common data.
     */
    const track = (options: TrackOptions): void => {
      debugAnalytics(options)

      const result = ZTrackEvent.safeParse({
        ...options,
        properties: {
          ...options.properties,
          bundle,
          custom: {
            ...options.properties.custom,
            connection_type,
          },
        },
        apiOptions: {
          ...commonData,
          anonymousId: deviceId,
        },
      })

      if (result.success) {
        const { event, properties, apiOptions } = result.data

        instance.track(event, properties, apiOptions)
      } else {
        const errors = result.error.errors
          .map(elem => `${elem.path}: ${elem.message}`)
          .join('\n')

        debugError('Sending Track Event', errors)
      }
    }

    return {
      rudderstack: instance,
      page,
      track,
    }
  }, [commonData, instance, manifestBundle])
}
