import type {
  ManualIntegration,
  TimecodeIntegration,
  UdpIntegration,
} from '../schemas/eventsync-config'
import type { Audio, AudioAsset } from '../schemas/vixen-assets'

export enum PlayerState {
  SYNCING = 'syncing',
  IDLE = 'idle',
  INITIALIZED = 'initialized',
  PLAYING = 'playing',
  ERROR = 'error',
}

export type VXTrack = {
  title: string
  url: string
}

export type EventTrack = {
  /** mapping of language to audio URL, at the moment just a path to local audio */
  url: string

  // Human readable name of this media
  title?: string

  // Indicates if the audio as part of a long audio file.
  // Long audio is media that may be to long to load in buffer as one file for syncing
  longAudio: boolean

  // sequence is use to identify the sequence of long audio files
  sequence: number

  // The full audio asset for this track
  asset?: AudioAsset

  // The audio object for this track
  audio?: Audio

  // Integration data specific to the track, includes a lot of
  // info, such as where in the timeline the track should be played and
  // what content should be played when the track is played
  integrationData:
    | TimecodeIntegration.TimecodeTimelineEntry
    | ManualIntegration.ManualTimelineEntry
    | UdpIntegration.UdpTimelineEntry
}

export type EventAudioBuffer = EventTrack & {
  // Duration of the buffer
  duration: number
  // The audio buffer
  buffer: AudioBuffer
}
