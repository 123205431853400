import type { EventTrack } from './types/player'

export const GA_EVENTS = {
  HOME: 'home',
  HEADPHONES: 'headphones_go',
  PLAYING: 'playing',
  INFO: 'information',
  SYNC_ISSUE: 'sync-issue',
  RELOADED: 'reloaded',
  STOP: 'stop',
  DURATION: 'duration',
}

export const BLANK_TRACK_ID = 'ES_BLANK_TRACK'

export const BLANK_TRACK = {
  url: '/assets/silent-placeholder.mp3',
  longAudio: false,
  sequence: 1,
  title: 'Silent Placeholder',
  integrationData: {
    contentId: BLANK_TRACK_ID,
  },
} as EventTrack

export const WEBSOCKET_CONFIG = {
  DELAY_BETWEEN_CLOCK_REQUESTS_MS: 200,
  RESYNC_DELAY_MS: 2 * 60 * 1000, // 2 minutes - AWS will kill the connection if there are no transfers for too long
  MIN_SAMPLES_NUMBER: 5,
  MAX_SAMPLES_NUMBER: 15,
  SETTLING_THRESHOLD: 1,
  ACCEPTABLE_ROUNDTRIP_MS: 1500,
  UNACCEPTABLE_RESPONSE: -69696969,
}

// `ANDROID_DEGRADATION_VERSION` is set to 10 when we really we want 9. This is due to google deciding to do this
// https://developers.google.com/privacy-sandbox/blog/user-agent-reduction-android-model-and-version
export const ANDROID_DEGRADATION_VERSION = 10
export const IOS_DEGRADATION_VERSION = 12

export const PLAYER_REFS = {
  AUDIO_CONTEXT: 'audioCtx',
  AUDIO_ELEMENT: 'audioElement',
  AUDIO_BUFFER: 'audioBuffer',
  AUDIO_BUFFER_SRC: 'bufferSrc',
  AUDIO_BUFFER_SRC_ARRAY: 'bufferSrcArray',
}
