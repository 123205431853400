import type { MutableRefObject } from 'react'
import { useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'radash'
import { useTranslation } from 'react-i18next'

import type { GazelleRef } from '@apsys/gazelle'

import { useAppContext } from 'context/AppContext'
import { useRefContext } from 'context/RefContext'
import type { AudioAsset } from 'schemas/vixen-assets'
import type { EventTrack } from 'types/player'
import { PlayerState } from 'types/player'
import { TrackEventType, useRudderstack } from 'utils/rudderstack'
import { useSettings } from 'utils/settings'

type Props = {
  /**
   * The current track being played
   */
  track: EventTrack | null
  /**
   * The current state of the player
   */
  playerState: PlayerState
}

/**
 * Sends events to Rudderstack when the user interacts with an audio element.
 *
 * See https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/2099773770/Event+Sync+-+Event+Specifications
 * for more information.
 */
export const useRudderstackAudioAnalytics = ({
  track,
  playerState,
}: Props): void => {
  const { i18n } = useTranslation()
  const {
    settings: { defaultLanguage },
  } = useSettings()

  const { getRef } = useRefContext()

  const audioCtx = () =>
    getRef('audioCtx') as MutableRefObject<AudioContext> | undefined

  const { track: rudderstackTrack } = useRudderstack()
  const { tracks } = useAppContext()

  const [longAudioTrackTargetRef, setLongAudioTrackTargetRef] = useState<
    GazelleRef<AudioAsset> | undefined
  >()
  const [longAudioDuration, setLongAudioDuration] = useState<number>(0)

  /**
   * If the track is a long audio, we need to calculate the total duration
   * of all long audio tracks with the same event ID, and set
   * the correct refs for the group and asset.
   */
  useEffect(() => {
    if (!track?.asset || !track.longAudio) return

    // for all tracks with long audio set to true and the same event ID, add up
    // the duration. If they don't have a duration, default to 0
    const longAudioTracks = tracks.filter(
      eventTrack =>
        eventTrack.integrationData.contentId ===
          track.integrationData.contentId && eventTrack.longAudio,
    )
    setLongAudioDuration(
      longAudioTracks.reduce((acc, t) => acc + (t.asset?.duration ?? 0), 0),
    )

    // set the group and asset refs for the long audio track where the
    // sequence=1 and it has the same event ID
    setLongAudioTrackTargetRef(
      longAudioTracks.find(t => t.sequence === 1)?.asset?.ref,
    )
  }, [track, tracks])

  /**
   * The group of the current track. If it's a long audio, we use the group
   * of the first track in the sequence.
   */
  const group = useMemo(() => {
    if (track?.longAudio) return undefined

    const name = isEmpty(track?.audio?.name[defaultLanguage])
      ? undefined
      : track?.audio?.name[defaultLanguage]

    return track?.audio
      ? {
          id: track?.audio.ref.id,
          typename: track?.audio.ref.typename,
          name,
        }
      : undefined
  }, [track, defaultLanguage])

  /**
   * Sends a `media_played` event to Rudderstack when the audio is playing
   */
  useEffect(() => {
    const trackEvent = () => {
      const ctx = audioCtx()?.current

      if (!track || !track.asset || !ctx) return

      const target =
        track.longAudio && longAudioTrackTargetRef
          ? longAudioTrackTargetRef
          : track.asset.ref

      rudderstackTrack({
        event: TrackEventType.MEDIA_PLAYED,
        properties: {
          target,
          group,
          language: track.asset?.language ?? i18n.language,
          duration: track.longAudio
            ? longAudioDuration
            : (track.asset?.duration ?? 0),
          duration_played: ctx.currentTime,
          custom: {
            content_id: track.integrationData.contentId,
            long_audio: track.longAudio,
          },
        },
      })
    }

    let intervalId: NodeJS.Timeout | undefined = undefined
    if (!track?.asset) {
      if (intervalId) {
        clearInterval(intervalId)
      }
    } else if (playerState === PlayerState.PLAYING) {
      // Set up interval to fire the event every 10 seconds
      intervalId = setInterval(trackEvent, 10000)
      // Fire the event immediately
      trackEvent()
    } else {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [playerState, track])
}
