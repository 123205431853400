import { useCallback, useMemo } from 'react'

import { findContentSlotByName } from '../utils/findContentSlotByName'

import { useHtmlBlock } from './useHtmlBlock'
import { usePicture } from './usePicture'

import { useAppContext } from 'context/AppContext'
import type { EsConfig } from 'schemas/eventsync-config'
import type { RasterImageAsset } from 'schemas/vixen-assets'
import { getEsConfig } from 'utils/bundle'
import { useSettings } from 'utils/settings'

/**
 * Get the current `es-config`
 *
 * This is the sticky venue (from the `ES_CONFIG` localStorage key) or
 * otherwise this is the first `es-config` in the bundle.
 *
 * The core logic of this function is supplied by {@link getEsConfig}.
 */
export function useEsConfig(): EsConfig | null {
  const { contentBundle } = useAppContext()

  return useMemo(() => {
    if (!contentBundle) return null

    return getEsConfig(contentBundle)
  }, [contentBundle])
}

export type ESConfigProperties = {
  logo: RasterImageAsset[]
  sponsors: RasterImageAsset[]
  background: RasterImageAsset[]
  name: string | undefined
  description: string | undefined
  socketEndpoint: string | undefined
  getInteractiveID: () => string
  integrationType: string | undefined
  customerSlug: string | undefined
  eventSlug: string | undefined
}

/**
 * The slotted content names for the `es-config` content slots.
 */
export enum ESCONFIG_CONTENT_SLOTNAMES {
  DETAILS = 'details',
  INACTIVE = 'inactive',
  INFORMATION = 'information',
  TRANSCRIPT = 'transcript',
}

export function useConfigProperties(): ESConfigProperties {
  const esConfig = useEsConfig()
  const { contentBundle } = useAppContext()

  const { settings } = useSettings()

  const eventInfo = contentBundle?.get(esConfig!.eventInfo)
  const connectionInfo = contentBundle?.get(esConfig!.connectionInfo)

  const interactiveID = eventInfo?.interactiveId
  const customerSlug = eventInfo?.customerSlug
  const eventSlug = eventInfo?.eventSlug

  const logo = usePicture(esConfig?.logo)
  const sponsors = usePicture(esConfig?.sponsor)
  const background = usePicture(esConfig?.background)
  const name = esConfig?.name[settings.defaultLanguage]

  const socketEndpoint = connectionInfo?.socketEndpoint
  const integrationType = esConfig?.integration.typename

  const descriptionSlot =
    esConfig?.content &&
    findContentSlotByName({
      slotName: ESCONFIG_CONTENT_SLOTNAMES.DETAILS,
      esconfigContent: esConfig.content,
    })
  const description = useHtmlBlock(descriptionSlot?.content)

  const configValues = useMemo(() => {
    return {
      logo,
      background,
      name,
      description,
      socketEndpoint,
      sponsors,
      integrationType,
      customerSlug,
      eventSlug,
    }
  }, [esConfig])

  //  returns an interactive ID. Falls back to:
  //  - Query parameters first as is standard
  //  - The id from EsConfig
  const getInteractiveID = useCallback((): string => {
    const urlParams = new URLSearchParams(location.search)
    const urlInteractiveId = urlParams.get('interactive_id') || ''
    if (urlParams.has('interactive_id') && urlInteractiveId)
      return urlInteractiveId
    return interactiveID || ''
  }, [interactiveID, esConfig, settings])

  return {
    getInteractiveID,
    ...configValues,
  }
}
