import type {
  MutableRefObject,
  PropsWithChildren,
  FunctionComponent,
} from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { v4 as uuidv4 } from 'uuid'

import { BLANK_TRACK } from '../constants'

import { useAppContext } from 'context/AppContext'
import { getSyncObject } from 'helpers/interactive'
import { useConfigProperties } from 'hooks/useEsConfig'
import { getDeviceID, setDeviceID } from 'storage/local-storage'
import type { EventTrack } from 'types/player'
import { PlayerState } from 'types/player'
import type {
  ChangeObject,
  StateObject,
  SyncObject,
  SystemObject,
} from 'types/sync'
import { SyncObjectPlayState, SystemObjectActiveState } from 'types/sync'
import WebsocketService from 'websocket-service'

let sessionId: string | null = null

export type SyncServerContextData = {
  clockSynced: boolean
  startTime: number
  changeObject?: ChangeObject
  socket: MutableRefObject<WebsocketService>
  playState: PlayerState
  setPlayState: (value: PlayerState) => void
  setTrack: (track: EventTrack) => void
  track: EventTrack | null
  isServerReady: boolean
  offlineMessages: string[]
  audioId: string
}

export const SyncServerContext = createContext<
  SyncServerContextData | undefined
>(undefined)

export function useSyncServerContext() {
  const context = useContext(SyncServerContext)
  if (!context) {
    throw new Error(
      "Component wasn't wrapped with a SyncServerContextProvider",
    )
  }
  return context
}

export type SyncServerContextProviderProps = PropsWithChildren<{
  onChange?(changeObject: ChangeObject): void
  onState?(stateObject: StateObject): void
}>

export const SyncServerContextProvider: FunctionComponent<
  SyncServerContextProviderProps
> = ({ children }) => {
  const [clockSynced, setClockSynced] = useState(false)
  const [startTime, setStartTime] = useState<number>(0)
  const [changeObject, setChangeObject] = useState<ChangeObject | undefined>()
  const [playState, setPlayState] = useState<PlayerState>(PlayerState.IDLE)
  const [track, setTrack] = useState<EventTrack | null>(null)
  const socket = useRef<WebsocketService>(
    WebsocketService.instantiate(
      '',
      () => {},
      () => {},
      () => {},
      () => {},
    ),
  )
  const [audioId, setAudioId] = useState<string>('')
  const [isServerReady, setIsServerReady] = useState(false)
  const { getInteractiveID, socketEndpoint } = useConfigProperties()

  // Sync server messages for intro screen
  const [offlineMessages, setOfflineMessages] = useState<string[]>([])

  const { tracks } = useAppContext()

  useEffect(() => {
    if (!changeObject) return

    if (changeObject.content_id !== audioId) {
      setAudioId(changeObject.content_id)
    }

    // Remove the elapsed time from the event time to get the start time
    setStartTime(changeObject.event_time - changeObject.elapsed_time)
  }, [changeObject])

  const getVXTrack = useCallback(
    (_audioId?: string): EventTrack => {
      // Find track by audio id and return it
      const locatedTrack = tracks.find(
        _track => _track.integrationData?.contentId === _audioId,
      )

      if (locatedTrack) return locatedTrack

      // If no track found return the blank track
      return BLANK_TRACK
    },
    [tracks],
  )

  useEffect(() => {
    // Update Track based on provided audioId
    const trackContent = getVXTrack(audioId)
    console.log('GET TRACK CONTENT', audioId, trackContent)
    if (trackContent) {
      setTrack(trackContent)
    }
  }, [audioId])

  // Called when we achieve clock sync.
  const handleClockReady = () => {
    if (clockSynced) return
    setClockSynced(true)
  }

  // Process the player state on server sync changes and reset the player if a play state is idle
  const handleSyncServerChange = (change: ChangeObject) => {
    const interactiveId = getInteractiveID()
    const syncObject = getSyncObject(interactiveId, change)

    if (syncObject?.play_state === SyncObjectPlayState.Idle) {
      // Apparently that's how the player stops the playback anyway
      window.location.reload()
    }
  }

  const handleSystem = (systemObject: SystemObject) => {
    console.info('handleSystem', systemObject)

    const { message, state } = systemObject
    setOfflineMessages(
      state === SystemObjectActiveState.Inactive ? [message] : [],
    )

    if (systemObject.state === SystemObjectActiveState.Active) {
      if (!isServerReady) {
        setIsServerReady(true)
      }
    }
  }

  // Called when we receive the interactive state graph at the start of a session.
  const handleState = (stateObject: StateObject) => {
    console.info('handleState', stateObject)
    stateObject.state.forEach((s: SyncObject) => {
      if (s.interactive_id === getInteractiveID()) {
        // If the play state is idle, just set the start time to epoch zero so nothing actually plays
        // If setting an actual time we remove the elapsed time from the event time to get the start time
        setStartTime(
          s.play_state == SyncObjectPlayState.Idle
            ? 0
            : s.event_time - s.elapsed_time,
        )
      }
    })

    // Set track based on the content_id
    const syncObject = getSyncObject(getInteractiveID(), stateObject)
    if (syncObject) {
      setAudioId(syncObject.content_id)
    }

    handleSystem({
      ...stateObject.system,
      type: 'system' as const,
    })
  }

  useEffect(() => {
    if (changeObject?.content_id && changeObject?.content_id !== audioId) {
      setAudioId(changeObject.content_id)
    }
  }, [changeObject])

  // Called when change messages are received.
  const handleChange = (co: ChangeObject) => {
    console.info(`handleChange ${co}, ${audioId}`)

    if (co.interactive_id === getInteractiveID()) {
      if (co.play_state === SyncObjectPlayState.Playing) {
        setChangeObject(co)
      } else if (co.play_state === SyncObjectPlayState.Idle) {
        // if the play state is idle, just set the start time to epoch zero so nothing actually plays
        co.event_time = 0
        setChangeObject(co)

        // Set content_id for Track from change object
        setAudioId(co.content_id)
      }
    }

    handleSyncServerChange(co)
  }

  if (!getDeviceID()) setDeviceID()

  const deviceId = getDeviceID()

  useEffect(() => {
    const socketURL = new URL(socketEndpoint || '')
    const socketSearchParams = new URLSearchParams(socketURL.search)
    if (deviceId) {
      socketSearchParams.set('did', deviceId)
    }
    if (sessionId) {
      socketSearchParams.set('sid', sessionId)
    }
    socketURL.search = socketSearchParams.toString()

    socket.current = WebsocketService.instantiate(
      `${socketURL}`,
      handleClockReady,
      handleState,
      handleChange,
      handleSystem,
    )

    // Connect to the sync server.
    socket.current.connect()
    return () => {
      if (socket.current) {
        socket.current.close()
      }
    }
  }, [])

  if (!sessionId) {
    sessionId = uuidv4()
  }

  return (
    <SyncServerContext.Provider
      value={{
        socket,
        clockSynced,
        startTime,
        changeObject,
        playState,
        setPlayState,
        track,
        setTrack,
        offlineMessages,
        isServerReady,
        audioId,
      }}
    >
      {children}
    </SyncServerContext.Provider>
  )
}
