/* eslint-disable */
import * as z from 'zod'
import { camel, snake } from 'radash'

import {
  Struct,
  Entity,
  Meta,
  recase,
  CasingFunction,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

export const meta: Meta = {
  name: 'Vixen Core - Manifest',
  moduleName: 'vixen-core-manifest',
  version: '0.9.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// --- audience ---
export enum Audience {
  PUBLIC = 'public',
  PREVIEW = 'preview',
}

// === interfaces ===
// --- manifest-entry ---
export interface ManifestEntrySchema {
  url: string
}

export interface IManifestEntry {
  url: string
}

// === structs ===
// === entities ===
export interface ManifestSchema {
  audience: Audience
  contents: Array<GazelleRefSchema>
}

export interface IManifest {
  audience: Audience
  contents: Array<GazelleRef<IManifestEntry>>
}

type ManifestSchemaType = z.Schema<Manifest, z.ZodTypeDef, ManifestSchema>

export const ManifestSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      audience: z.nativeEnum(Audience),
      contents: z.array(GazelleRefSchema),
    }),
  )
  .transform(
    value => new Manifest(recase(value, camel)),
  ) satisfies ManifestSchemaType

export class Manifest extends Entity<ManifestSchema> implements IManifest {
  static readonly typename = 'manifest'
  static readonly schema: ManifestSchemaType = ManifestSchema
  static readonly parse = ManifestSchema.parse
  static readonly fields = ['ref', 'audience', 'contents']
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'manifest'

  ref!: GazelleRef<Manifest>
  audience!: Audience
  contents!: Array<GazelleRef<IManifestEntry>>

  constructor(attrs: IManifest & WithRef<Manifest>) {
    super(attrs)
  }
}

export interface GazelleContentBundleSchema extends ManifestEntrySchema {
  'schema-module': string
  'schema-version': string
  format: 'json' | 'bson'
}

export interface IGazelleContentBundle extends IManifestEntry {
  schemaModule: string
  schemaVersion: string
  format: 'json' | 'bson'
}

type GazelleContentBundleSchemaType = z.Schema<
  GazelleContentBundle,
  z.ZodTypeDef,
  GazelleContentBundleSchema
>

export const GazelleContentBundleSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'schema-module': z.string(),
      'schema-version': z.string(),
      format: z.enum(['json', 'bson']),
    }),
  )
  .transform(
    value => new GazelleContentBundle(recase(value, camel)),
  ) satisfies GazelleContentBundleSchemaType

export class GazelleContentBundle
  extends Entity<GazelleContentBundleSchema>
  implements IGazelleContentBundle
{
  static readonly typename = 'gazelle-content-bundle'
  static readonly schema: GazelleContentBundleSchemaType =
    GazelleContentBundleSchema
  static readonly parse = GazelleContentBundleSchema.parse
  static readonly fields = [
    'ref',
    'url',
    'schema-module',
    'schema-version',
    'format',
  ]
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'gazelle-content-bundle'

  ref!: GazelleRef<GazelleContentBundle>
  url!: string
  schemaModule!: string
  schemaVersion!: string
  format!: 'json' | 'bson'

  constructor(attrs: IGazelleContentBundle & WithRef<GazelleContentBundle>) {
    super(attrs)
  }
}

export type INTERFACES = {
  'manifest-entry': IManifestEntry
}

export const ENTITIES = {
  manifest: Manifest,
  'gazelle-content-bundle': GazelleContentBundle,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.9.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
