import type { ESCONFIG_CONTENT_SLOTNAMES } from 'hooks/useEsConfig'
import type { EsConfig } from 'schemas/eventsync-config'
import type * as OcelotContent from 'schemas/ocelot-content'

/**
 * Find a content slot by it's content slot name. Intended to be used with
 * `useEsConfig` and `ESCONFIG_CONTENT_SLOTNAMES` but does take a string as
 * a backup
 *
 * @param slotName
 * @param esconfigContent
 */
export const findContentSlotByName = ({
  slotName,
  esconfigContent,
}: {
  slotName: ESCONFIG_CONTENT_SLOTNAMES | string
  esconfigContent: EsConfig['content']
}): OcelotContent.SlottedContent.ContentSlot | undefined => {
  return esconfigContent.find(content => content.slot === slotName)
}
