import styled from 'styled-components'

import { useConfigProperties } from 'hooks/useEsConfig'
import { pxToRem } from 'theme/utils'

export const LogoElement = styled.img`
  width: ${pxToRem(220)};
  height: auto;
  max-height: ${pxToRem(136)}; // golden ratio
  object-fit: contain;
  object-position: top left;
  margin: 0 auto;
`

/**
 * The logo component.
 *
 * @returns The logo component.
 */
export const Logo = (): JSX.Element | null => {
  const { logo } = useConfigProperties()

  const logoUrl = logo[0]?.url

  if (!logoUrl) {
    return null
  }

  return <LogoElement src={logoUrl} alt="Logo" />
}
