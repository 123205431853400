import type { GazelleRef } from '@apsys/gazelle'

import type {
  ManualIntegration,
  TimecodeIntegration,
  UdpIntegration,
} from 'schemas/eventsync-config'
import type { Audio } from 'schemas/vixen-assets'

/**
 * get the timeline entry by the audio ref
 *
 */
export const getTimelineEntryByAudio = (
  integrations: Array<
    ManualIntegration | UdpIntegration | TimecodeIntegration
  >,
  audio: GazelleRef<Audio>,
):
  | TimecodeIntegration.TimecodeTimelineEntry
  | ManualIntegration.ManualTimelineEntry
  | UdpIntegration.UdpTimelineEntry
  | undefined => {
  for (const integration of integrations) {
    for (const entry of integration.timeline) {
      if (entry.audio.id === audio.id) {
        return entry
      }
    }
  }

  return undefined
}
