import type { MutableRefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { GA_EVENTS, PLAYER_REFS } from '../constants'

import { useAudioBuffer } from './useAudioBuffer'

import { useAppContext } from 'context/AppContext'
import { useRefContext } from 'context/RefContext'
import { useSyncServerContext } from 'context/SyncServerContext'
import { gaSendEvent } from 'helpers/analytics'
import { getConfigData } from 'helpers/config'
import { playNewAudioNode } from 'helpers/playNewAudioNode'
import {
  checkIsHighDelayAllowancePercent,
  getCurrentDelay,
  setupIOSMediaSession,
} from 'helpers/player'
import { getIsAndroid } from 'helpers/ua'
import { PlayerState } from 'types/player'
import { useRudderstackAudioAnalytics } from 'utils/rudderstack'

const isAndroid = getIsAndroid()

export function useAudioPlayer() {
  const { t } = useTranslation()

  const { tracks } = useAppContext()
  const {
    clockSynced,
    startTime,
    socket,
    setPlayState,
    track,
    setTrack,
    audioId,
  } = useSyncServerContext()

  const audioDiff = useRef<number>(0)
  const streamOffset = useRef<number>(0)
  const reloadCount = useRef<number>(0)
  const reloadOffset = useRef<number>(0)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const interval = useRef<ReturnType<typeof setInterval> | null>(null)

  const [state, setState] = useState<PlayerState>(PlayerState.IDLE)

  const [audioGain, setAudioGain] = useState<number>(0)
  const gainNode = useRef<GainNode>()
  const [audioAllowed, setAudioAllowed] = useState(false)
  const [bufferLoaded, setBufferLoaded] = useState(false)
  const [muted, setMuted] = useState(false)

  const {
    bufferCounter,
    setupBuffer,
    setBuffer,
    replaceBuffer,
    replaceLongAudioBuffer,
    longAudioTime,
    allBuffersLoaded,
  } = useAudioBuffer()

  const { getRef, setRefValue } = useRefContext()

  const audioCtx = () =>
    getRef(PLAYER_REFS.AUDIO_CONTEXT) as
      | MutableRefObject<AudioContext>
      | undefined

  const audioRef = () =>
    getRef(PLAYER_REFS.AUDIO_ELEMENT) as
      | MutableRefObject<HTMLAudioElement | null>
      | undefined

  const storedBufferSrc = () =>
    getRef(PLAYER_REFS.AUDIO_BUFFER_SRC) as
      | MutableRefObject<AudioBufferSourceNode | null>
      | undefined

  // We store the buffer src in a ref, then update
  // that ref so we can swap out the bufferSrc when we need to.
  // This way we can hot-swap the actual playing track
  // in scenarios where we don't have an actual <audio> element; ie.
  // Android.
  const storedBuffer = () =>
    getRef(PLAYER_REFS.AUDIO_BUFFER) as
      | MutableRefObject<AudioBuffer | null>
      | undefined

  const audioConfig = getConfigData('audioConfig')

  const loadAudio = async (): Promise<void> => {
    console.info('🚛 🎶  Loading Audio buffer', track, audioCtx()?.current)
    // If there's no audio context or track, return.
    // IMPORTANT as sometimes even the empty audio track is passed in but fails
    // to load
    if (!audioCtx()?.current || !track) return

    try {
      await setupBuffer(track, startTime)
      setState(PlayerState.INITIALIZED)
      setBufferLoaded(true) // Set bufferLoaded to true after setting up the buffer
    } catch (err) {
      console.error(`❌ Error Loading Audio ${err}`)
    }
  }

  const toggleMuted = (): void => {
    setMuted(!muted)
    const currentAudioRef = audioRef()?.current
    const ctx = audioCtx()?.current
    if (!currentAudioRef || !ctx) return
    currentAudioRef.muted = !muted

    // Android muting
    if (isAndroid && audioCtx()?.current && gainNode.current) {
      gainNode.current.gain.setValueAtTime(!muted ? -1 : 1, ctx.currentTime)
    }
  }

  /**
   * Reload the audio context if we have a sync issue.
   */
  const reloadAudioContext = (): void => {
    // Prevent a reload if we have exceeded the allowance or reload in a given time.
    // Having this happen will prompt the user with a sync error.
    // Sync/delay issues are caused by operations coming in on mobile devices
    // such as audio output changes and incoming phone calls.
    // Having this insures we don't get hung in a permanent re-sync.
    if (reloadCount.current >= audioConfig.reloadAllowances.max) {
      setState(PlayerState.ERROR)
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
      return
    }
    const ctx = audioCtx()?.current

    if (!track || !ctx) return

    // If we're on a reload loop where we've reloaded more than once rapidly
    // trying to recover, usually on an old phone or slow network, add
    // additional time to the reload offset to counter the rapid reloads.
    // Normal reloads are except from this
    if (reloadCount.current > 1) {
      reloadOffset.current = getCurrentDelay({
        audioCtx: ctx,
        socket,
        startTime,
        audioDiff,
        streamOffset,
      })
    }

    // TODO: DO WE REALLY WANT TO HIGHLIGHT A DESYNC EVEN WHEN IT IS SO MINOR
    setState(PlayerState.SYNCING)
    console.info(
      `🔃 🎶 Reload, count:${reloadCount.current} track: ${track.url}`,
    )
    streamOffset.current = ctx.currentTime + reloadOffset.current

    // FIXME: STOP BUFFER
    setBuffer(track)
    // TODO: DISABLED AS WE DONT NEED TO SET TO INITIALISED AS IT WILL FORCE A RERENDER ON PLAYER VIEW
    // setState(PlayerState.INITIALIZED)

    // Track amount of reloads and clear what has been tracked if no reload have happened.
    reloadCount.current = reloadCount.current + 1

    // If we have successfully reloaded, we clear the reload count and offset
    // after a short delay
    if (timer.current === null) {
      timer.current = setTimeout(() => {
        reloadCount.current = 0
        reloadOffset.current = 0
        timer.current = null
      }, audioConfig.reloadAllowances.timeMs)
    }
  }

  const delayCheck = () => {
    const ctx = audioCtx()?.current

    if (!ctx || state !== PlayerState.PLAYING || !ctx.currentTime) return

    const bufferDuration = storedBufferSrc()?.current?.buffer?.duration
    if (!bufferDuration || !track) {
      return
    }

    // Reload if delay exceeds allowance
    if (
      checkIsHighDelayAllowancePercent(
        ctx,
        socket,
        startTime,
        audioDiff,
        streamOffset,
        bufferDuration,
      )
    ) {
      console.info('⚠️ Audio delay allowance to high. Re-loading')
      reloadAudioContext()
    } else {
      if (state !== PlayerState.PLAYING) setState(PlayerState.PLAYING)

      // Long audio check
      if (track.longAudio) {
        const epoch = socket.current.getEpochTime()
        let diff = (epoch - startTime) / 1000.0
        // Prevent negative diff
        diff = diff > 0 ? diff : 0

        // Update/replace the long audio buffer if required.
        const longAudioBufferTrack = replaceLongAudioBuffer(track, diff, state)

        // If audio buffer was replaced with above check we update the stored track.
        if (longAudioBufferTrack && longAudioBufferTrack !== track) {
          setTrack(longAudioBufferTrack)
        }
      }
    }
  }

  const playSoundAndroid = (offset: number) => {
    const currentStoredBufferSrc = storedBufferSrc()?.current
    const currentStoredBuffer = storedBuffer()?.current
    const ctx = audioCtx()?.current

    if (!ctx || !currentStoredBuffer) return

    const newBufferSrc = playNewAudioNode({
      audioCtx: ctx,
      buffer: currentStoredBuffer,
      offset,
      gainNode: gainNode.current,
      existingSrc: currentStoredBufferSrc,
      connect: true,
    })

    setRefValue(PLAYER_REFS.AUDIO_BUFFER_SRC, newBufferSrc)
  }

  const playSoundGeneral = (offset: number) => {
    const currentAudio = audioRef()?.current
    const currentStoredBufferSrc = storedBufferSrc()?.current
    const currentStoredBuffer = storedBuffer()?.current
    const ctx = audioCtx()?.current

    if (!ctx || !currentStoredBuffer || !currentAudio) return
    const streamNode = ctx.createMediaStreamDestination()

    const newBufferSrc = playNewAudioNode({
      audioCtx: ctx,
      buffer: currentStoredBuffer,
      offset,
      gainNode: gainNode.current,
      existingSrc: currentStoredBufferSrc,
      connect: false,
    })

    setRefValue(PLAYER_REFS.AUDIO_BUFFER_SRC, newBufferSrc)
    newBufferSrc.connect(streamNode)
    currentAudio.controls = true
    currentAudio.srcObject = streamNode.stream
    currentAudio.volume = audioGain
    const playPromise = currentAudio.play()
    // Catch play promise errors for when the play() request is interrupted by a new load request.
    if (playPromise !== undefined) {
      playPromise.catch(error => {})
    }
  }

  const playSound = () => {
    setAudioAllowed(true)

    if (!bufferLoaded || !track || !audioAllowed) {
      return
    }
    const epoch = socket.current.getEpochTime()
    let diff = (epoch - startTime) / 1000.0

    // Prevent negative diff
    diff = diff > 0 ? diff : 0

    audioDiff.current = diff

    const diffLong = diff - longAudioTime

    let offset = track.longAudio && longAudioTime > 0 ? diffLong : diff
    offset = offset > 0 ? offset : 0

    console.group('▶️ Play Sound')
    console.info(`⏲ Start Time: ${startTime}`)
    console.info(`⏳ Play back diff: ${diff}`)
    if (track.longAudio) {
      console.info(`⏳ Play back diff (Long Audio): ${diffLong}`)
    }
    console.info(` Offset: ${offset}`)
    console.groupEnd()

    console.log('🎶 Play Sound on Buffer Change', bufferCounter)

    if (isAndroid) {
      console.log('🎶 Play Sound Android')
      playSoundAndroid(offset)
    } else {
      console.log('🎶 Play Sound General')
      playSoundGeneral(offset)
    }

    setState(PlayerState.PLAYING)
  }

  useEffect(() => {
    console.info('🎛 Setup Sync Player 🎛', bufferLoaded, track)

    if (bufferLoaded) return

    const run = async () => {
      const aContext = audioCtx()?.current
      await loadAudio()
      if (!aContext) return
      // Ensure we don't get audio suspension when changing browser tabs or locking mobile device screens.
      aContext.onstatechange = () => {
        if (aContext.state === 'suspended' || aContext.state !== 'running') {
          if (aContext.state !== 'closed') aContext.resume()
        }
      }
      // Listen for device changes and run a delay check when on is detected.
      if (window.navigator.mediaDevices) {
        window.navigator.mediaDevices.ondevicechange = () => {
          delayCheck()
        }
      }

      // Resume the play at least once to ensure audio is allowed initially
      // Fixes a bug on safari where audio starts in a suspended state and never plays
      aContext.resume()
      const currentAudioRef = audioRef()?.current
      if (currentAudioRef) {
        // Preventing headphone hardware be able to pause
        currentAudioRef.addEventListener('pause', () => {
          currentAudioRef.play()
        })
      }

      setupIOSMediaSession(t)

      gaSendEvent(GA_EVENTS.HEADPHONES)
    }

    run()
  }, [tracks.length, track])

  useEffect(() => {
    // Initial Delay check
    setTimeout(() => {
      delayCheck()
      setAudioGain(1)
    }, 500)

    interval.current = setInterval(
      () => {
        delayCheck()
      },
      track?.longAudio
        ? audioConfig.longAudioDelayCheckFrequencyMs
        : audioConfig.delayCheckFrequencyMs,
    )

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [startTime, state])

  useEffect(() => {
    setPlayState(state)
    if (state == PlayerState.ERROR) {
      if (interval.current) clearInterval(interval.current)
      const ctx = audioCtx()?.current
      if (ctx) {
        ctx.close()
      }
      gaSendEvent(GA_EVENTS.SYNC_ISSUE)
    }
  }, [state])

  useEffect(() => {
    if (!bufferLoaded) return

    if (audioId) {
      console.log('🎶 Replace Buffer on AudioID Change', audioId)
      replaceBuffer(audioId, state)
    }
  }, [audioId, bufferLoaded])

  useEffect(() => {
    // Start audio if we have clock sync and a new bufferSrc has been set.
    if (clockSynced && audioAllowed && bufferLoaded) {
      playSound()
    }
  }, [bufferCounter])

  useEffect(() => {
    // Reload Audio with new offsets if playing
    if (state === PlayerState.PLAYING) {
      reloadAudioContext()
    }
  }, [startTime])

  const play = useCallback((): boolean => {
    const ctx = audioCtx()?.current

    if (
      !clockSynced ||
      state !== PlayerState.INITIALIZED ||
      !bufferLoaded ||
      !ctx
    ) {
      return false
    }

    // Set initial offset
    streamOffset.current = ctx.currentTime

    playSound()
    reloadAudioContext()
    return true
  }, [clockSynced, state, audioAllowed, bufferLoaded, reloadAudioContext])

  useEffect(() => {
    // Set up gain node for Android muting
    const ctx = audioCtx()?.current
    if (isAndroid && !gainNode.current && ctx) {
      gainNode.current = ctx.createGain()
      gainNode.current.connect(ctx.destination)
    }
  }, [])

  /**
   * Rudderstack Audio Analytics tracking!
   */
  useRudderstackAudioAnalytics({
    track,
    playerState: state,
  })

  return {
    state,
    play,
    toggleMuted,
    muted,
    allBuffersLoaded,
  }
}
