/* eslint-disable */
import * as z from 'zod'
import { camel, snake } from 'radash'

import {
  Struct,
  Entity,
  Meta,
  recase,
  CasingFunction,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenAssets from './vixen-assets'
import * as OcelotContent from './ocelot-content'

export const meta: Meta = {
  name: 'Event Sync Festival Config',
  moduleName: 'esfestival-config',
  version: '1.5.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- es-integration ---
export interface EsIntegrationSchema {
  name?: string
}

export interface IEsIntegration {
  name: string
}

// === structs ===
// === entities ===
export interface ManualIntegrationSchema extends EsIntegrationSchema {
  timeline?: Array<ManualIntegration.ManualTimelineEntrySchema>
}

export interface IManualIntegration extends IEsIntegration {
  timeline: Array<ManualIntegration.ManualTimelineEntry>
}

type ManualIntegrationSchemaType = z.Schema<
  ManualIntegration,
  z.ZodTypeDef,
  ManualIntegrationSchema
>

export const ManualIntegrationSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.string().default(''),
      timeline: z
        .array(ManualIntegration.ManualTimelineEntrySchema)
        .default([]),
    }),
  )
  .transform(
    value => new ManualIntegration(recase(value, camel)),
  ) satisfies ManualIntegrationSchemaType

export class ManualIntegration
  extends Entity<ManualIntegrationSchema>
  implements IManualIntegration
{
  static readonly typename = 'manual-integration'
  static readonly schema: ManualIntegrationSchemaType = ManualIntegrationSchema
  static readonly parse = ManualIntegrationSchema.parse
  static readonly fields = ['ref', 'name', 'timeline']
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'manual-integration'

  ref!: GazelleRef<ManualIntegration>
  name!: string
  timeline!: Array<ManualIntegration.ManualTimelineEntry>

  constructor(attrs: IManualIntegration & WithRef<ManualIntegration>) {
    super(attrs)
  }
}

export namespace ManualIntegration {
  export interface ManualTimelineEntrySchema {
    'content-id': string
    audio: GazelleRefSchema
  }

  export interface IManualTimelineEntry {
    contentId: string
    audio: GazelleRef<VixenAssets.Audio>
  }

  type ManualTimelineEntrySchemaType = z.Schema<
    ManualTimelineEntry,
    z.ZodTypeDef,
    ManualTimelineEntrySchema
  >

  export const ManualTimelineEntrySchema = z
    .lazy(() =>
      z.object({
        'content-id': z.string(),
        audio: GazelleRefSchema,
      }),
    )
    .transform(
      value => new ManualTimelineEntry(recase(value, camel)),
    ) satisfies ManualTimelineEntrySchemaType

  export class ManualTimelineEntry
    extends Struct<ManualTimelineEntrySchema>
    implements IManualTimelineEntry
  {
    static readonly typename = 'manual-timeline-entry'
    static readonly schema: ManualTimelineEntrySchemaType =
      ManualTimelineEntrySchema
    static readonly parse = ManualTimelineEntrySchema.parse
    static readonly fields = ['content-id', 'audio']
    static readonly casingFunction: CasingFunction = camel

    readonly typename = 'manual-timeline-entry'

    contentId!: string
    audio!: GazelleRef<VixenAssets.Audio>

    constructor(attrs: IManualTimelineEntry) {
      super(attrs)
    }
  }
}

export interface UdpIntegrationSchema extends EsIntegrationSchema {
  port: number
  timeline?: Array<UdpIntegration.UdpTimelineEntrySchema>
}

export interface IUdpIntegration extends IEsIntegration {
  port: number
  timeline: Array<UdpIntegration.UdpTimelineEntry>
}

type UdpIntegrationSchemaType = z.Schema<
  UdpIntegration,
  z.ZodTypeDef,
  UdpIntegrationSchema
>

export const UdpIntegrationSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.string().default(''),
      port: z.number().int(),
      timeline: z.array(UdpIntegration.UdpTimelineEntrySchema).default([]),
    }),
  )
  .transform(
    value => new UdpIntegration(recase(value, camel)),
  ) satisfies UdpIntegrationSchemaType

export class UdpIntegration
  extends Entity<UdpIntegrationSchema>
  implements IUdpIntegration
{
  static readonly typename = 'udp-integration'
  static readonly schema: UdpIntegrationSchemaType = UdpIntegrationSchema
  static readonly parse = UdpIntegrationSchema.parse
  static readonly fields = ['ref', 'name', 'port', 'timeline']
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'udp-integration'

  ref!: GazelleRef<UdpIntegration>
  name!: string
  port!: number
  timeline!: Array<UdpIntegration.UdpTimelineEntry>

  constructor(attrs: IUdpIntegration & WithRef<UdpIntegration>) {
    super(attrs)
  }
}

export namespace UdpIntegration {
  export interface UdpTimelineEntrySchema {
    'content-id': string
    audio: GazelleRefSchema
  }

  export interface IUdpTimelineEntry {
    contentId: string
    audio: GazelleRef<VixenAssets.Audio>
  }

  type UdpTimelineEntrySchemaType = z.Schema<
    UdpTimelineEntry,
    z.ZodTypeDef,
    UdpTimelineEntrySchema
  >

  export const UdpTimelineEntrySchema = z
    .lazy(() =>
      z.object({
        'content-id': z.string(),
        audio: GazelleRefSchema,
      }),
    )
    .transform(
      value => new UdpTimelineEntry(recase(value, camel)),
    ) satisfies UdpTimelineEntrySchemaType

  export class UdpTimelineEntry
    extends Struct<UdpTimelineEntrySchema>
    implements IUdpTimelineEntry
  {
    static readonly typename = 'udp-timeline-entry'
    static readonly schema: UdpTimelineEntrySchemaType = UdpTimelineEntrySchema
    static readonly parse = UdpTimelineEntrySchema.parse
    static readonly fields = ['content-id', 'audio']
    static readonly casingFunction: CasingFunction = camel

    readonly typename = 'udp-timeline-entry'

    contentId!: string
    audio!: GazelleRef<VixenAssets.Audio>

    constructor(attrs: IUdpTimelineEntry) {
      super(attrs)
    }
  }
}

export interface TimecodeIntegrationSchema extends EsIntegrationSchema {
  timeline?: Array<TimecodeIntegration.TimecodeTimelineEntrySchema>
}

export interface ITimecodeIntegration extends IEsIntegration {
  timeline: Array<TimecodeIntegration.TimecodeTimelineEntry>
}

type TimecodeIntegrationSchemaType = z.Schema<
  TimecodeIntegration,
  z.ZodTypeDef,
  TimecodeIntegrationSchema
>

export const TimecodeIntegrationSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.string().default(''),
      timeline: z
        .array(TimecodeIntegration.TimecodeTimelineEntrySchema)
        .default([]),
    }),
  )
  .transform(
    value => new TimecodeIntegration(recase(value, camel)),
  ) satisfies TimecodeIntegrationSchemaType

export class TimecodeIntegration
  extends Entity<TimecodeIntegrationSchema>
  implements ITimecodeIntegration
{
  static readonly typename = 'timecode-integration'
  static readonly schema: TimecodeIntegrationSchemaType =
    TimecodeIntegrationSchema
  static readonly parse = TimecodeIntegrationSchema.parse
  static readonly fields = ['ref', 'name', 'timeline']
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'timecode-integration'

  ref!: GazelleRef<TimecodeIntegration>
  name!: string
  timeline!: Array<TimecodeIntegration.TimecodeTimelineEntry>

  constructor(attrs: ITimecodeIntegration & WithRef<TimecodeIntegration>) {
    super(attrs)
  }
}

export namespace TimecodeIntegration {
  export interface TimecodeTimelineEntrySchema {
    'content-id': string
    trigger: string
    'start-point': number
    'time-code': string
    audio: GazelleRefSchema
  }

  export interface ITimecodeTimelineEntry {
    contentId: string
    trigger: string
    startPoint: number
    timeCode: string
    audio: GazelleRef<VixenAssets.Audio>
  }

  type TimecodeTimelineEntrySchemaType = z.Schema<
    TimecodeTimelineEntry,
    z.ZodTypeDef,
    TimecodeTimelineEntrySchema
  >

  export const TimecodeTimelineEntrySchema = z
    .lazy(() =>
      z.object({
        'content-id': z.string(),
        trigger: z.string(),
        'start-point': z.number(),
        'time-code': z.string(),
        audio: GazelleRefSchema,
      }),
    )
    .transform(
      value => new TimecodeTimelineEntry(recase(value, camel)),
    ) satisfies TimecodeTimelineEntrySchemaType

  export class TimecodeTimelineEntry
    extends Struct<TimecodeTimelineEntrySchema>
    implements ITimecodeTimelineEntry
  {
    static readonly typename = 'timecode-timeline-entry'
    static readonly schema: TimecodeTimelineEntrySchemaType =
      TimecodeTimelineEntrySchema
    static readonly parse = TimecodeTimelineEntrySchema.parse
    static readonly fields = [
      'content-id',
      'trigger',
      'start-point',
      'time-code',
      'audio',
    ]
    static readonly casingFunction: CasingFunction = camel

    readonly typename = 'timecode-timeline-entry'

    contentId!: string
    trigger!: string
    startPoint!: number
    timeCode!: string
    audio!: GazelleRef<VixenAssets.Audio>

    constructor(attrs: ITimecodeTimelineEntry) {
      super(attrs)
    }
  }
}

export interface EventInfoSchema {
  'interactive-id': string
  'customer-slug': string
  'event-slug': string
  audience: string
}

export interface IEventInfo {
  interactiveId: string
  customerSlug: string
  eventSlug: string
  audience: string
}

type EventInfoSchemaType = z.Schema<EventInfo, z.ZodTypeDef, EventInfoSchema>

export const EventInfoSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      'interactive-id': z.string(),
      'customer-slug': z.string(),
      'event-slug': z.string(),
      audience: z.string(),
    }),
  )
  .transform(
    value => new EventInfo(recase(value, camel)),
  ) satisfies EventInfoSchemaType

export class EventInfo extends Entity<EventInfoSchema> implements IEventInfo {
  static readonly typename = 'event-info'
  static readonly schema: EventInfoSchemaType = EventInfoSchema
  static readonly parse = EventInfoSchema.parse
  static readonly fields = [
    'ref',
    'interactive-id',
    'customer-slug',
    'event-slug',
    'audience',
  ]
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'event-info'

  ref!: GazelleRef<EventInfo>
  interactiveId!: string
  customerSlug!: string
  eventSlug!: string
  audience!: string

  constructor(attrs: IEventInfo & WithRef<EventInfo>) {
    super(attrs)
  }
}

export interface ConnectionInfoSchema {
  'bridge-endpoint': string
  'socket-endpoint': string
}

export interface IConnectionInfo {
  bridgeEndpoint: string
  socketEndpoint: string
}

type ConnectionInfoSchemaType = z.Schema<
  ConnectionInfo,
  z.ZodTypeDef,
  ConnectionInfoSchema
>

export const ConnectionInfoSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      'bridge-endpoint': z.string(),
      'socket-endpoint': z.string(),
    }),
  )
  .transform(
    value => new ConnectionInfo(recase(value, camel)),
  ) satisfies ConnectionInfoSchemaType

export class ConnectionInfo
  extends Entity<ConnectionInfoSchema>
  implements IConnectionInfo
{
  static readonly typename = 'connection-info'
  static readonly schema: ConnectionInfoSchemaType = ConnectionInfoSchema
  static readonly parse = ConnectionInfoSchema.parse
  static readonly fields = ['ref', 'bridge-endpoint', 'socket-endpoint']
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'connection-info'

  ref!: GazelleRef<ConnectionInfo>
  bridgeEndpoint!: string
  socketEndpoint!: string

  constructor(attrs: IConnectionInfo & WithRef<ConnectionInfo>) {
    super(attrs)
  }
}

export interface EsConfigSchema
  extends VixenCore.NamedSchema,
    OcelotContent.SlottedContentSchema {
  integration: GazelleRefSchema
  playlists?: Array<GazelleRefSchema>
  'class-name'?: string | null
  background?: GazelleRefSchema | null
  logo?: GazelleRefSchema | null
  sponsor?: GazelleRefSchema | null
  'event-info': GazelleRefSchema
  'connection-info': GazelleRefSchema
}

export interface IEsConfig
  extends VixenCore.INamed,
    OcelotContent.ISlottedContent {
  integration: GazelleRef<IEsIntegration>
  playlists: Array<GazelleRef<IEsIntegration>>
  className?: string
  background?: GazelleRef<VixenAssets.Picture>
  logo?: GazelleRef<VixenAssets.Picture>
  sponsor?: GazelleRef<VixenAssets.Picture>
  eventInfo: GazelleRef<EventInfo>
  connectionInfo: GazelleRef<ConnectionInfo>
}

type EsConfigSchemaType = z.Schema<EsConfig, z.ZodTypeDef, EsConfigSchema>

export const EsConfigSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      content: z
        .array(OcelotContent.SlottedContent.ContentSlotSchema)
        .default([]),
      name: z.record(z.string()),
      integration: GazelleRefSchema,
      playlists: z.array(GazelleRefSchema).default([]),
      'class-name': z.string().nullish(),
      background: GazelleRefSchema.nullish(),
      logo: GazelleRefSchema.nullish(),
      sponsor: GazelleRefSchema.nullish(),
      'event-info': GazelleRefSchema,
      'connection-info': GazelleRefSchema,
    }),
  )
  .transform(
    value => new EsConfig(recase(value, camel)),
  ) satisfies EsConfigSchemaType

export class EsConfig extends Entity<EsConfigSchema> implements IEsConfig {
  static readonly typename = 'es-config'
  static readonly schema: EsConfigSchemaType = EsConfigSchema
  static readonly parse = EsConfigSchema.parse
  static readonly fields = [
    'ref',
    'content',
    'name',
    'integration',
    'playlists',
    'class-name',
    'background',
    'logo',
    'sponsor',
    'event-info',
    'connection-info',
  ]
  static readonly casingFunction: CasingFunction = camel

  readonly typename = 'es-config'

  ref!: GazelleRef<EsConfig>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  integration!: GazelleRef<IEsIntegration>
  playlists!: Array<GazelleRef<IEsIntegration>>
  className?: string
  background?: GazelleRef<VixenAssets.Picture>
  logo?: GazelleRef<VixenAssets.Picture>
  sponsor?: GazelleRef<VixenAssets.Picture>
  eventInfo!: GazelleRef<EventInfo>
  connectionInfo!: GazelleRef<ConnectionInfo>

  constructor(attrs: IEsConfig & WithRef<EsConfig>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES &
  OcelotContent.INTERFACES & {
    'es-integration': IEsIntegration
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...OcelotContent.ENTITIES,
  'manual-integration': ManualIntegration,
  'udp-integration': UdpIntegration,
  'timecode-integration': TimecodeIntegration,
  'event-info': EventInfo,
  'connection-info': ConnectionInfo,
  'es-config': EsConfig,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.5.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
