/**
 * Ideally, we don't store
 * any AudioBuffer, AudioContext or AudioBufferSourceNode
 * in react state. It causes memory leaks and lots of
 * problems in the render loop. This util is intended
 * to play a new audio node and stop the existing one.
 */
export const playNewAudioNode = ({
  audioCtx,
  buffer,
  offset,
  gainNode,
  existingSrc,
  connect,
}: {
  connect: boolean
  audioCtx: AudioContext
  buffer: AudioBuffer
  offset: number
  gainNode?: GainNode
  existingSrc?: AudioBufferSourceNode | null
}) => {
  if (existingSrc) {
    existingSrc.stop()
    existingSrc.disconnect()
  }
  const src = audioCtx.createBufferSource()
  src.buffer = buffer
  // If we're streaming, we don't want to connect. (in the android case)
  if (connect) {
    src.connect(audioCtx.destination)
  }
  if (gainNode) {
    src.connect(gainNode)
  }
  src.start(0, offset)
  return src
}
